<script context="module" lang="ts">
  // wasn't working in safari?
  //  import iconsSvg from '../assets/icons/fairpass-icons.svg';
</script>

<script lang="ts">
  export let name;

  let klass = 'w-4 h-4';
  // export let fill = undefined;
  // export let stroke = undefined;
  export { klass as class };
  export let x = 0;
  export let y = 0;
  export let width = '100%';
  export let height = '100%';
</script>

{#if name === 'fairpass/troll'}
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    class="pointer-events-none {klass}"
    viewBox="0 0 36 36"
    {x}
    {y}
    {width}
    {height}
  >
    <path
      fill="#898379"
      d="M6 23c-4 0-4.5 2.5-4.5 2.5L0 25v11h36V25l-1 1s-1-3-4-3H6z"
    />
    <path
      fill="#394150"
      d="M34.712 11.129C32.343 4.839 25.009 3.077 18 3.077c-.15 0-.285.006-.432.007-.77-.018-5.121-.148-7.939-.897A.498.498 0 0 0 9 2.67V4s-4.126-.688-6.676-1.493a.501.501 0 0 0-.579.734l2.14 3.567C.736 10.28 3 15.31 3 18c0 8 3 8 3 8h23c3.497 0 4-4.503 4-8 0-1.451.599-2.767.137-5.338a.93.93 0 0 1 1.15-1.063.358.358 0 0 0 .425-.47z"
    /><path
      fill="#69615D"
      d="M9 29c-.155 0-.309.036-.447.105C5.602 30.581 5.038 35.898 5 36h26c-.038-.102-.602-5.419-3.553-6.895A1.002 1.002 0 0 0 27 29H9z"
    /><path
      fill="#A6D388"
      d="M9 30c-1.148.574-1.307 1.806-1.235 2.752a5.396 5.396 0 0 1-.551 2.821L7 36h22l-.214-.427a5.396 5.396 0 0 1-.551-2.821c.072-.946-.087-2.178-1.235-2.752H9z"
    /><path
      fill="#77B255"
      d="M17.946 33.923c4.734 0 8.014-.675 10.285-1.886-.062-.783-.357-1.6-1.231-2.037H9c-.86.43-1.16 1.229-1.228 2.001 2.365 1.304 5.675 1.922 10.174 1.922z"
    /><path
      fill="#A6D388"
      d="M24.354 14.222c1.045.385 2.045 1.714 2.175 2.448.13.735.261 1.329-.565.594-3.312-2.942-6.919-1.783-10.495-3.581-2.497-1.255-3.257-2.644-3.257-2.644s-.305 1.853-4.091 3.741c-1.46.729-3.122.443-3.122 3.22 0 7.483-1.258 13.923 12.947 13.923 11.26 0 12.87-3.814 13.054-9.121.003-3.604-.463-5.009-1.598-6.272-.992-1.105-2.263-1.993-5.048-2.308z"
    /><path fill="#77B255" d="M20 24h-4a1 1 0 0 1 0-2h4a1 1 0 0 1 0 2z" /><path
      fill="#744629"
      d="M23 28H13a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2z"
    /><path
      d="M15 16h-4a1 1 0 0 0 0 2h1v2a1 1 0 1 0 2 0v-2h1a1 1 0 0 0 0-2zm10 0h-4a1 1 0 0 0 0 2h1v2a1 1 0 1 0 2 0v-2h1a1 1 0 0 0 0-2z"
      fill="#2FA875"
    /><path
      d="M31 13c2 2 2 5 2 5l3-5s-2-2-5 0zM5 13c-2 2-2 5-2 5l-3-5s2-2 5 0z"
      fill="#A6D388"
    /><path
      d="M.983 30.656a.1.1 0 0 1-.116.028.1.1 0 0 1-.06-.104c.095-.792.452-2.923 1.623-4.209a.1.1 0 0 1 .174.061c.129 1.932-1.116 3.623-1.621 4.224zM.95 33.678c-.01.038.004.079.035.104s.074.028.109.009c.462-.248.983-.784 1.221-1.485.289-.853.269-1.434.226-1.723a.102.102 0 0 0-.073-.082.1.1 0 0 0-.104.034 6.14 6.14 0 0 0-.794 1.279c-.299.655-.521 1.485-.62 1.864zm3.594-7.465a.1.1 0 0 0-.07-.085.097.097 0 0 0-.105.031c-.758.89-.897 2.264-.922 2.817a.1.1 0 0 0 .173.074 3.532 3.532 0 0 0 .924-2.837zm1.169 3.024a.1.1 0 0 0-.06-.108.1.1 0 0 0-.119.033c-.951 1.296-2.363 4.513-2.831 5.606a.1.1 0 0 0 .033.12.1.1 0 0 0 .124-.004c.515-.44 1.532-1.404 2.012-2.528.483-1.135.739-2.485.841-3.119zm24.448.423a.101.101 0 0 0-.114-.005.1.1 0 0 0-.044.105c.23 1.084.886 2.467 1.942 3.3a.1.1 0 0 0 .114.007.1.1 0 0 0 .046-.105c-.121-.615-.559-2.244-1.944-3.302zm4.729 4.28a.1.1 0 0 0 .145-.119c-.235-.677-1.032-2.6-2.692-3.283a.097.097 0 0 0-.104.018.1.1 0 0 0-.03.102c.525 1.879 2.097 2.943 2.681 3.282zm-.695-3.493a.1.1 0 0 0 .128-.127c-.374-1.05-1.476-2.639-2.95-3.014a.1.1 0 0 0-.12.126c.629 2.025 2.365 2.806 2.942 3.015zm.763-1.791a.1.1 0 0 0 .16-.102c-.263-1.09-1.298-2.795-2.646-3.282a.1.1 0 0 0-.105.024.102.102 0 0 0-.023.105c.552 1.499 2.058 2.809 2.614 3.255z"
      fill="#69615D"
    /><path
      d="M31.505 13.587c.638-.509 1.681-.932 3.117-.571a.5.5 0 1 1-.244.97c-1.224-.309-1.959.114-2.327.447a6.29 6.29 0 0 0-.546-.846zm-27.01-.001c-.637-.509-1.68-.932-3.117-.571a.5.5 0 1 0 .244.97c1.225-.308 1.959.115 2.327.446a6.41 6.41 0 0 1 .546-.845z"
      fill="#77B255"
    />
    <path d="M13 27v-2s2 .667 2 2h-2zm10 0v-2s-2 .667-2 2h2z" fill="#FFEBA5" />
  </svg>
{:else if name === 'fairpass/unicorn'}
  <!-- src: twemoji 1f984-->
  <svg
    aria-hidden="true"
    xmlns="http://www.w3.org/2000/svg"
    class="pointer-events-none {klass}"
    viewBox="0 0 36 36"
    {x}
    {y}
    {width}
    {height}
  >
    <path
      fill="#C1CDD5"
      d="M36 19.854C33.518 9.923 25.006 1.909 16.031 6.832c0 0-4.522-1.496-5.174-1.948-.635-.44-1.635-.904-.912.436.423.782.875 1.672 2.403 3.317C8 12.958 9.279 18.262 7.743 21.75c-1.304 2.962-2.577 4.733-1.31 6.976 1.317 2.33 4.729 3.462 7.018 1.06 1.244-1.307.471-1.937 3.132-4.202 2.723-.543 4.394-1.791 4.394-4.375 0 0 .795-.382 1.826 6.009.456 2.818-.157 5.632-.039 8.783H36V19.854z"
    /><path
      fill="#60379A"
      d="M31.906 6.062c.531 1.312.848 3.71.595 5.318-.15-3.923-3.188-6.581-4.376-7.193-2.202-1.137-4.372-.979-6.799-.772.111.168.403.814.32 1.547-.479-.875-1.604-1.42-2.333-1.271-1.36.277-2.561.677-3.475 1.156-.504.102-1.249.413-2.372 1.101-1.911 1.171-4.175 4.338-6.737 3.511 1.042 2.5 3.631 1.845 3.631 1.845 1.207-1.95 4.067-3.779 6.168-4.452 7.619-1.745 12.614 3.439 15.431 9.398.768 1.625 2.611 7.132 4.041 10.292V10.956c-.749-1.038-1.281-3.018-4.094-4.894z"
    /><path
      fill="#C1CDD5"
      d="M13.789 3.662c.573.788 3.236.794 4.596 3.82 1.359 3.026-1.943 2.63-3.14 1.23-1.334-1.561-1.931-2.863-2.165-3.992-.124-.596-.451-2.649.709-1.058z"
    /><path
      fill="#758795"
      d="M14.209 4.962c.956.573 2.164 1.515 2.517 2.596.351 1.081-.707.891-1.349-.042-.641-.934-.94-1.975-1.285-2.263-.346-.289.117-.291.117-.291z"
    /><circle fill="#292F33" cx="15.255" cy="14.565" r=".946" /><path
      fill="#53626C"
      d="M8.63 26.877c.119.658-.181 1.263-.67 1.351-.49.089-.984-.372-1.104-1.03-.119-.659.182-1.265.671-1.354.49-.088.984.373 1.103 1.033z"
    /><path
      fill="#EE7C0E"
      d="M13.844 8.124l.003-.002-.005-.007-.016-.014c-.008-.007-.011-.019-.019-.025-.009-.007-.021-.011-.031-.018C12.621 7.078.933-.495.219.219-.51.948 10.443 9.742 11.149 10.28l.011.006.541.439c.008.007.01.018.018.024.013.01.028.015.042.024l.047.038-.009-.016c.565.361 1.427.114 1.979-.592.559-.715.577-1.625.066-2.079z"
    /><path
      fill="#C43512"
      d="M4.677 2.25l.009-.025c-.301-.174-.594-.341-.878-.5-.016.038-.022.069-.041.11-.112.243-.256.484-.429.716-.166.224-.349.424-.541.595-.02.018-.036.026-.056.043.238.22.489.446.745.676.234-.21.456-.449.654-.717.214-.287.395-.589.537-.898zm2.275 2.945c.306-.41.521-.822.66-1.212-.292-.181-.584-.36-.876-.538-.076.298-.247.699-.586 1.152-.31.417-.613.681-.864.845.259.223.52.445.779.665.314-.244.619-.552.887-.912zM9.87 7.32c.365-.49.609-.983.734-1.437l-.906-.586c-.023.296-.172.81-.631 1.425-.412.554-.821.847-1.1.978l.814.671c.381-.256.761-.611 1.089-1.051z"
    />
  </svg>
{/if}
